
    
    flex-grow: 1;

    
    display: flex;

    @media (--viewportMedium) {
      justify-content: center;
      align-items: flex-start;
    }
  