.tableWrapper{
    width: 80%;
    margin: 40px auto;
    @media(max-width: 768px){
        width: 100%;
    }
}


.tableBottomText{
    color: var(--matterColorAnti);
    line-height: 22px;

    @media(max-width: 768px){
        margin-bottom: 120px;
    }
}