
    
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 0 5px 0;

    
    appearance: none;

    
    border: none;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: var(--marketplaceColor);
    border-radius: 0;

    
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
    background-size: 16px 16px;
    background-position: center right;

    

    cursor: pointer;
    transition: border-bottom-color var(--transitionStyle);

    &:hover,
    &:focus {
      border-bottom-color: var(--matterColor);
      outline: none;
    }

    @media (--viewportMedium) {
      padding: 4px 0 2px 0;
    }
  