
    flex-grow: 1;

    
    position: relative;

    
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0;

    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);

    @media (--viewportMedium) {
      flex-basis: 480px;
      flex-grow: 0;
      height: 100%;
      padding: 0;
      background-color: transparent;
      border-radius: 0;
    }
  