@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    padding: 0 25px;
    overflow: auto;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  @apply --marketplaceH2FontStyles;
  /* color: var(--marketplaceColor); */
  margin: 0 24px;
  font-weight: 200;
  margin-top: 0;
  margin-left: 20px;
  @media (--viewportMedium) {
    margin: 0;
    margin-top: 0;
    margin-left: 20px;
  }
}

.singlePrice {
  @apply --marketplaceH2FontStyles;
  /* color: var(--marketplaceColor); */
  margin: 0;
  font-weight: 200;
  margin-top: 0;
  @media (--viewportMedium) {
    margin: 0;
    margin-top: 0;
  }
}

.priceCut {
  @apply --marketplaceH2FontStyles;
  /* color: var(--marketplaceColor); */
  margin: 0 24px;
  font-weight: 200;
  margin-bottom: 10px;
  text-decoration: line-through;
  @media (--viewportMedium) {
    margin: 0;
  }
}

.author {
  display: flex;
  flex-direction: row;
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin: 24px 24px 32px 24px;

  @media (--viewportMedium) {
    margin: 24px 0 32px 0;
    color: var(--matterColor);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 20px 0;
  }
}

.providerAvatar {
  margin-right: 10px;
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    margin-bottom: 0;
    justify-content: space-between;
  }
}

.orderTitle {
  /* Font */
  color: var(--matterColor);
  display: flex;
  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  display: none;
  @apply --marketplaceH5FontStyles;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.bookingDatesSubmitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px var(--mobileBottomNavBarHeight) 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;

  @media (max-width: 900px) {
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    margin: 0;
    width: 100%;
    padding: 0 20px;

    & div {
      margin: 0;
    }
  }
}

.priceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.priceValueCut {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  text-decoration: line-through;
  margin-top: 0;
  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.orderButton {
  @apply --defaultButtonStyle;
  border: none;
  min-width: 150px;
  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;

  @media (max-width: 900px) {
    max-height: 50px;
    /* margin: auto auto;
    margin-top: 20px;
    margin-bottom: 10px; */
    width: 40%;
  }
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.favButtonWrapper {
  margin: auto 0;
  @media only screen and (max-width: 900px) {
    padding: 0 24px;
  }
  @media only screen and (min-width: 900px) {
    margin-left: 10px;
  }
}

.favButtonNotSelected {
  cursor: pointer;
  display: block;

  transform: scale(1.3);
  &:hover {
    transform: scale(1.6);
    filter: invert(18%) sepia(96%) saturate(6390%) hue-rotate(332deg) brightness(93%) contrast(108%);
  }
}

.titleWrapper {
  display: flex;
  flex-direction: row;
}

.favButtonSelected {
  cursor: pointer;
  display: block;
  filter: invert(18%) sepia(96%) saturate(6390%) hue-rotate(332deg) brightness(93%) contrast(108%);

  transform: scale(1.3);
  &:hover {
    transform: scale(1.6);
  }
}

.link {
  margin-left: 5px;
}

.authorInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.sendMessageWrapper {
  @apply --defaultButtonStyle;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 15px;
  margin-left: 2pc;
  text-align: center;
  line-height: 22px;
  min-width: 130px;
  @media (max-width: 900px) {
    margin-left: 0;
    width: 100%;
  }

  &:hover {
    text-decoration: none;
  }
}

.sendMessageWrapperDisabled {
  @apply --defaultButtonStyle;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 15px;
  margin-left: 2pc;
  text-align: center;
  line-height: 22px;
  @media (max-width: 900px) {
    margin-left: 0;
    width: 45%;
  }
}

.messageIcon {
  margin-right: 5px;
  width: auto;
  height: 25px;
}

.type {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(198, 198, 198);
  margin-top: 1pc;
  padding-bottom: 1pc;
}

.brand {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(198, 198, 198);
  margin-top: 1pc;
  padding-bottom: 1pc;
}

.accordionLabel {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  color: rgb(74, 74, 74);
  font-size: 16px;
}

.otherInfoItems {
  display: flex;
  flex-direction: column;
}

.otherInfoItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.otherInfoItemLabel {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  color: #87878f;
  font-size: 16px;
  font-weight: 500;
}

.otherInfoItemValue {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  color: rgb(74, 74, 74);
  font-size: 16px;
  font-weight: 500;
}

.categories {
  display: flex;
  margin-top: 1pc;
  padding-bottom: 1pc;
}

.categories > a {
  margin-left: 5px;
  text-decoration: underline;
  color: black;

  &:hover {
    color: var(--marketplaceColor);
  }
}

.priceWrapper {
  display: flex;
}

.dualButtonsWrapper {
  display: flex;
  width: 67%;
  justify-content: flex-start;

  @media (max-width: 900px) {
    width: 100%;
    justify-content: space-between;
  }
}

.mButtonsWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.deliveryModalTitle {
  @media (max-width: 768px) {
    margin-top: 80px;
  }
}
