
    @apply --marketplaceButtonStyles;

    background-color: var(--successColor);
    color: var(--matterColorLight);

    &:hover,
    &:focus {
      background-color: var(--successColorDark);
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColor);
    }
  