@import '../../../styles/customMediaQueries.css';
@import '../../../styles/propertySets.css';

.quantityField {
  margin-bottom: 40px;
  @media (--viewportMedium) {
    padding: 0;
  }
}

.deliveryField {
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.singleDeliveryMethodSelected {
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.breakdownWrapper {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }
}

.submitButton {
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 72px;
  }
}

.submitButton > button{
  @apply --defaultButtonStyle;
}

.finePrint {
  @apply --marketplaceTinyFontStyles;
  text-align: center;
}

.error {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.addressButtonsWrapper{
  display: flex;
  justify-content: center;

  @media(max-width: 500px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}

.addressButton{
  width: 200px;
  height: 50px;
  background-color: var(--marketplaceGreen);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 25px;
  cursor: pointer;
  &:hover{
    background-color: var(--marketplaceDarkGreen);
  }
}

.addressButtonDisabled{
  width: 200px;
  height: 50px;
  background-color: rgb(202, 202, 202);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
}

.commingSoonText{
font-size: 14px;
font-weight: 100;
margin-top: -12px;
font-style: italic;
}

.addressButtonSelected{
  width: 200px;
  height: 50px;
  background-color: var(--marketplaceDarkGreen);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 25px;
}

.formInput{
  margin-top: 20px;
}

.submitAddressForm{
  margin: 40px auto 0 auto;
  min-width: 200px;

  @media(max-width: 900px){
    margin-bottom: 150px;
  }
}

.submitAddressFormDisabled{
  margin: 40px auto 0 auto;
  min-width: 200px;
  pointer-events: none;
  background-color: rgb(202, 202, 202);
  @media(max-width: 900px){
    margin-bottom: 150px;
  }
}

.inputLabel{
  font-weight: var(--fontWeightSemiBold);
  padding-top: 15px;
  padding-bottom: 0px;
  font-size: 14px;
  color: var(--matterColor);
}

.ownAddressOption{
  margin-top: 40px;
}

.selectAddressOptionNotSelected{
  border: 2px solid grey;
  border-radius: 15px;
  padding: 15px;
  cursor: pointer;
  margin-bottom: 20px;
  &:hover{
    border: 2px solid var(--marketplaceDarkGreen);
  }
}

.selectAddressOptionSelected{
  border: 2px solid var(--marketplaceDarkGreen);
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 20px;
}

.modalContentWrapper{

}

.addAddressButton{
  cursor: pointer;
  max-width: 150px;
  margin-bottom: 40px;

  @media(max-width: 768px){
    margin-bottom: 150px;
  }
  &:hover{
    color: var(--marketplaceDarkGreen);
  }
}

.addAddressButton2{
  cursor: pointer;
  max-width: 150px;
  margin-bottom: 40px;

  @media(max-width: 768px){
    margin-bottom: 20px;
  }
  &:hover{
    color: var(--marketplaceDarkGreen);
  }
}

.addressButtonsMidText{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  margin: 0 20px;
}

.modalTitle{
 margin-bottom: 0;
 @media(max-width: 768px){
  margin-top: 50px;
 }
}

.modalSubtitle{
 color: grey;
 margin-top: 0;
}

.selectAddressOptionDisabled{
  border: 2px solid grey;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 20px;
  background-color: rgb(202, 202, 202);

}

.speedyOfficeField{
  margin-top: 20px;
}

.pickupDate{
  margin-bottom: 20px;
}

.pickupTime{
  margin-bottom: 20px;
}

.lastPickupTime{
  margin-bottom: 20px;
}
.deleteIcon:hover{
  color: red;
}