@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--matterColorNegative); /* Loading BG color */
  border-radius: 4px;
  padding-bottom: 0;
  position: relative;
  /* height: 18pc;
  width: 12pc; */
  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.soldLabel {
  position: absolute;
  top: 10px;
  right: 0;
  background-color: var(--marketplaceColor);
  padding: 0 10px;
  /* z-index: 8; */

  @media only screen and (min-width: 950px) {
    right: 45px;
  }
}

.rootForImage {
  /* border: solid 1px var(--matterColorNegative); */
  border-radius: 4px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 16px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-shrink: 0;
}

.priceValue {
  /* Font */
  @apply --marketplaceDefaultFontStyles;

  /* Font */
  color: #4a4a4a;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  display: inline-flex;
  align-items: center;
  margin-left: 8px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceDefaultFontStyles;
  line-height: 24px;
  color: var(--matterColor);
  text-align: center;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.parentDiv {

  display: inline-block; /* or 'flex' depending on layout needs */
  position: relative;
  width: 100%;
  
}

/* .favButtonWrapper {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 5;
} */


.namedLink, .aspectRatioWrapper {
  width: 100%;
  height: auto; /* Adjust height to be auto or based on the content */
}
