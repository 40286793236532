
    
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 0 5px 0;

    
    border: none;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: var(--marketplaceColor);
    border-radius: 0;

    &::placeholder {
      color: var(--matterColorAnti);
    }

    

    transition: border-bottom-color var(--transitionStyle);

    &:hover,
    &:focus {
      border-bottom-color: var(--matterColor);
      outline: none;
    }

    &:disabled {
      color: var(--matterColorAnti);
      border-bottom-color: var(--matterColorAnti);
    }

    @media (--viewportMedium) {
      padding: 4px 0 2px 0;
    }
  