
    font-weight: var(--fontWeightBold);
    font-size: 36px;
    line-height: 36px;
    letter-spacing: -0.7px;
    
    margin-top: 25px;
    margin-bottom: 14px;

    @media (--viewportMedium) {
      font-size: 64px;
      line-height: 64px;
      letter-spacing: -1.5px;
      
      margin-top: 25px;
      margin-bottom: 23px;
    }
  