
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    
    margin-top: 12px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 32px;
      
      margin-top: 16px;
      margin-bottom: 16px;
    }
  