
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;

    @media (--viewportLarge) {
      font-weight: var(--fontWeightSemiBold);
      font-size: 20px;
      line-height: 24px;
    }
  