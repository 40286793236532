
    font-weight: var(--fontWeightRegular);
    font-size: 16px;
    line-height: 24px;
    margin: 18px 0 0 0;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightRegular);
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.11px;
      margin: 24px 0 0 0;
    }
  