
    font-weight: var(--fontWeightSemiBold);
    font-size: 21px;
    line-height: 24px;
    
    margin-top: 21px;
    margin-bottom: 17px;

    @media (--viewportMedium) {
      line-height: 32px;
      
      margin-top: 21px;
      margin-bottom: 19px;
    }
  