/* @import '../../marketplace.css'; */

.pageTitle {
  text-align: center;
  margin-bottom: 0;
}

.pageSubTitle{
  margin-top: 0;
  margin-bottom: 2pc;
  font-size: 20px;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.coverImage {
  width: 100%;
  height: 528px;
  border-radius: 4px;
  object-fit: cover;
  margin: 32px 0 40px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 3pc;
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  width: 90%;

  @media (--viewportMedium) {
    max-width: 650px;
  }
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  margin-top: 48px;
  margin-bottom: 24px;
}


.giftcardWrapper{
  margin-top: 15px;
  margin-bottom: 3pc;
}

.wrapper{
  display: flex;
  width: 100%;
  justify-content: space-between;
}


/* payment form */

.labelsWrapper{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
}

.label2{
    margin-right: -3pc;
}

.label3{
}

.quantityWrapper{
  display: flex;
  justify-content: space-between;
}


.quantityPicker{
  height: 4pc;
  @media only screen and (max-width: 800px){
   width: 9pc;
  }
}

.quantityPicker > span{
  display: flex;
  transform: scale(0.6);
}

.quantityPicker > span > button{
  height: 4pc;
}

.label{
  @media only screen and (max-width: 800px){
    padding: 10px 0;
  }
}

.error{
  color: red;
  text-align: center;
}

.success{
  color: rgb(0, 223, 0);
  line-height: 22px;
  text-align: center;
}

.emailLabel{
  line-height: 22px;
  margin-bottom: 0;
  border-top: 1px solid grey;
  padding-top: 1pc;
}

.noMargin{
  margin: 0;
}

.noMarginLast{
  margin: 0;
  margin-bottom: 2pc;
}

.epicLogo{
  height: 2pc;
}

.buyIcon > svg:hover{
  color: var(--marketplaceColor);
}

.termsAndConditions{
  display: flex;
}

.checkbox > svg{
  color: var(--marketplaceColor);
}

.resendEmailWrapper{
  margin: 1pc 0;
  font-size: 17px;
  color: grey;
  cursor: pointer;
  &:hover{
    color: var(--marketplaceColor)
  }
}

.waitingInfoText{
  margin: 1pc 0;
  font-size: 17px;
  color: grey;
}

.spinner > svg{
 color: var(--marketplaceColor);
 transform: scale(0.7);
}

.spinner{
  margin: 0 auto;
 }

.spinnerMiddle > svg{
  color: var(--marketplaceColor);
  transform: scale(0.7);
 }

 .spinnerMiddle{
  margin: 0 auto;
 }

 .optionsWrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
 }

 .option{
  display: flex;
  justify-content: space-between;
  border: 1px solid grey;
  box-shadow: 0 .1rem .3rem #000;
  border-radius: 15px;
  padding: 0 10px;
  cursor: pointer;
  margin-bottom: 1pc;
  &:hover{
    border: 1px solid var(--marketplaceColor);
    box-shadow: 0 .1rem .3rem var(--marketplaceColor);
    transform: scale(1.05);
  }
 }


 .optionSelected{
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--marketplaceColor);
  box-shadow: 0 .1rem .3rem var(--marketplaceColor);
  transform: scale(1.05);
  border-radius: 15px;
  padding: 0 10px;
  cursor: pointer;
  margin-bottom: 1pc;
  &:hover{
    border: 1px solid var(--marketplaceColorLight);
    box-shadow: 0 .1rem .3rem var(--marketplaceColorLight);
  }
 }

 .optionLeft{

 }

 .optionRight{
 color: var(--marketplaceColor);
 }

 .couponInputWrapper{
   display: flex;
 }

 .couponInput{
  max-width: 100px;
}

.submitCouponCodeButton{
  margin-left: 15px;
  border-radius: 10px;
  color: var(--marketplaceColor);
  cursor: pointer;
  &:hover{
    transform: scale(1.03);
  }
}

.submitCouponCodeButtonDisabled{
  margin-left: 15px;
  border-radius: 10px;
  background-color: rgb(218, 218, 218);
}

.submitButton{
  @apply --defaultButtonStyle;
  min-width: 150px;
  width: 45%;

  @media(max-width: 768px){
    width: 100%;
    margin-bottom: 20px;
  }
}

.submitButtonDisabled{
  @apply --defaultButtonStyle;
  min-width: 150px;
  width: 45%;

  /* disabled specific styles */
  background-color: rgb(220, 220, 220);
  pointer-events: none;
  /* -- */

  @media(max-width: 768px){
    width: 100%;
  }
}

.bumpCreditsButton{
  @apply --defaultButtonStyle;
  min-width: 150px;
  width: 45%;

  @media(max-width: 768px){
    width: 100%;
  }
}

.bumpCreditsButtonDisabled{
  @apply --defaultButtonStyle;
  min-width: 150px;
  width: 45%;
  /* disabled specific styles */
  background-color: rgb(220, 220, 220);
  pointer-events: none;
  /* -- */
  @media(max-width: 768px){
    width: 100%;
  }
}

.buttonWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;

  @media(max-width: 768px){
    flex-direction: column;
  }
}